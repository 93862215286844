import {transform} from './transformToSelectOptions';

export const setSectorOptions = (sectors, industry) => {
  const list = sectors.filter(sector => sector.industry.id === industry.id);
  return transform(list, industry.sectors);
};

export const getSector = (sectors, id) => {
  return sectors.find(k => k.id === id);
};

export const disableSector = (data, id) => {
  return data.find(i => i && i.id === id) ? true : false;
};
