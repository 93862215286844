import { toggleModal } from '../../../../core/store/actions/Modal';
import store from '../../../../core/store/configureStore';
import { toast } from 'react-toastify';

export function successfulAwardsUpdate() {
    store.dispatch(toggleModal());
    toast.success('Update successful!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
    });
}

export function failedAwardsUpdate() {
    store.dispatch(toggleModal());
    toast.error('Update failed!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
    });
}
