import { GetListReducerBuilder, ReducerSet, SingleReducerBuilder } from '../../../../utils/reducers';
import { FETCH_PLANS, START_FREE_TRIAL, IDENTIFIER } from '../../actions/Subscription';

export default {
    subscription: GetListReducerBuilder.build(IDENTIFIER, FETCH_PLANS),
    
    startFreeTrial: new SingleReducerBuilder(IDENTIFIER, false, true, false)
        .addReducerSet(ReducerSet.create(
            START_FREE_TRIAL,
            (state, payload) => ({
                ...state,
                pending: false,
                entity: payload,
                error: null
            })
        )).build()
};
