import { all } from 'redux-saga/effects';
import { WatchersBuilder } from '../../../../utils/sagas/WatchersBuilder';
import { APPROVE, IDENTIFIER, REJECT, FETCH_FACEBOOK_DATA, FETCH_LINKEDIN_DATA, DELETE_PRODUCT, ATTACH_AWARD, REMOVE_AWARD } from '../actions';
import { environment } from '../../../../environments/environment';
import { approve, reject, fetchFacebookData, fetchLinkedinkData, deleteProduct, attachAward, removeAward } from '../../api';
import {
    navigateToList,
    failureEditCompany,
    successEditCompany,
    fetchFacebookDataMessage,
    fetchLinkedinDataMessage,
    deleteProductCallback,
    attachAwardCallback,
    removeAwardCallback
} from './workers';

export default function* () {
    yield all([
        ...WatchersBuilder.buildGetSingle(
            IDENTIFIER,
            environment.api.companies
        ),
        WatchersBuilder.build(
            IDENTIFIER,
            APPROVE,
            approve,
            navigateToList
        ),
        WatchersBuilder.build(
            IDENTIFIER,
            REJECT,
            reject,
            navigateToList
        ),
        WatchersBuilder.build(
            IDENTIFIER,
            ATTACH_AWARD,
            attachAward,
            attachAwardCallback
        ),
        WatchersBuilder.build(
            IDENTIFIER,
            REMOVE_AWARD,
            removeAward,
            removeAwardCallback
        ),
        WatchersBuilder.build(
            IDENTIFIER,
            FETCH_FACEBOOK_DATA,
            fetchFacebookData,
            fetchFacebookDataMessage
        ),
        WatchersBuilder.build(
            IDENTIFIER,
            FETCH_LINKEDIN_DATA,
            fetchLinkedinkData,
            fetchLinkedinDataMessage
        ),
        WatchersBuilder.build(
            IDENTIFIER,
            DELETE_PRODUCT,
            deleteProduct,
            deleteProductCallback
        ),
        failureEditCompany(),
        successEditCompany()
    ]);
}
