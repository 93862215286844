import { all } from 'redux-saga/effects';
import { WatchersBuilder } from '../../../../utils/sagas/WatchersBuilder';
import { IDENTIFIER } from '../actions';
import { environment } from '../../../../environments/environment';
import { closeModal } from './workers';

export default function* () {
    yield all(WatchersBuilder.buildList(
        IDENTIFIER,
        environment.api.admins,
        {
            create: { successCallback: closeModal },
            update: { successCallback: closeModal }
        }
    ));
}
