import {compose} from 'redux';
import {connect} from 'react-redux';
import {withFormik} from 'formik';
import InnerLoginForm from './InnerLoginForm';
import {ValidationBuilder} from '../../../../core/services/validation';
import {login} from '../../../../core/store/actions/Auth';

const validationBuilder = new ValidationBuilder()
    .required('email', 'Email')
    .required('password', 'Password');

const config = {
    mapPropsToValues: props => ({
        email: '', password: ''
    }),

    validate: validationBuilder.build(),

    handleSubmit: (values, {props: {submitlogin}}) => {
        submitlogin(values);
    }
};

const mapDispatchToProps = {
    submitlogin: login
};

export default compose(
    connect(null, mapDispatchToProps),
    withFormik(config)
)(InnerLoginForm);
