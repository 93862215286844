import * as React from 'react';
import {Card, CardBody, CardGroup, Col, Container, Row} from 'reactstrap';
import LoginForm from './components/LoginForm';

export const Login = () =>
    <div className="app flex-row align-items-center">
        <Container>
            <Row className="justify-content-center">
                <Col md="6">
                    <CardGroup>
                        <Card className="p-4">
                            <CardBody>
                                <LoginForm/>
                            </CardBody>
                        </Card>
                    </CardGroup>
                </Col>
            </Row>
        </Container>
    </div>;

