import * as React from 'react';
import Loadable from 'react-loadable';
import { MainLayout } from './pages/MainLayout';
import { PAGE_ROUTES } from './constants/pageRoutes';
import { IRoute } from './core/interfaces/common';

function Loading() {
    return <div>Loading...</div>;
}

const Dashboard = Loadable({
    loader: () => import('./pages/Dashboard'),
    loading: Loading
});

const ManageCompanies = Loadable({
    loader: () => import('./pages/ManageCompanies'),
    loading: Loading
});

const NewCompanies = Loadable({
    loader: () => import('./pages/NewCompanies'),
    loading: Loading
});

const PartnerCompanies = Loadable({
    loader: () => import('./pages/PartnerCompanies'),
    loading: Loading
});

const Company = Loadable({
    loader: () => import('./pages/CompanyDetails'),
    loading: Loading
});

const Admins = Loadable({
    loader: () => import('./pages/Admins'),
    loading: Loading
});

const ReportedCompanies = Loadable({
    loader: () => import('./pages/ReportedCompanies'),
    loading: Loading
});

const ReportedCompany = Loadable({
    loader: () => import('./pages/ReportedCompanies/pages/ReportedCompany'),
    loading: Loading
});

const ReportedConversations = Loadable({
    loader: () => import('./pages/ReportedConversations'),
    loading: Loading
});

const ReportedConversation = Loadable({
    loader: () => import('./pages/ReportedConversations/pages/ReportedConversation'),
    loading: Loading
});

const ChangePassword = Loadable({
    loader: () => import('./pages/ChangePassword'),
    loading: Loading
});

const Industries = Loadable({
    loader: () => import('./pages/Industries'),
    loading: Loading
});

const Sectors = Loadable({
    loader: () => import('./pages/Sectors'),
    loading: Loading
});

const Products = Loadable({
    loader: () => import('./pages/Products'),
    loading: Loading
});

const PopularProducts = Loadable({
    loader: () => import('./pages/Products/components/PopularProductsTable'),
    loading: Loading
});

const EmailsGenerator = Loadable({
    loader: () => import('./pages/EmailsGenerator'),
    loading: Loading
});

const AdditionalStats = Loadable({
    loader: () => import('./pages/AdditionalStats'),
    loading: Loading
});

const Downloads = Loadable({
    loader: () => import('./pages/Downloads'),
    loading: Loading
});

const Surveys = Loadable({
    loader: () => import('./pages/Surveys'),
    loading: Loading
});

const ManageAwards = Loadable({
    loader: () => import('./pages/ManageAwards'),
    loading: Loading
});

export const routesAdmin: IRoute[] = [
    {
        path: PAGE_ROUTES.HOME,
        exact: true,
        name: 'Home',
        component: MainLayout
    },
    {
        path: PAGE_ROUTES.DASHBOARD,
        name: 'Dashboard',
        component: Dashboard
    },
    {
        path: PAGE_ROUTES.ADMINS,
        exact: true,
        name: 'Administrators',
        component: Admins
    },
    {
        path: PAGE_ROUTES.MANAGE_COMPANIES,
        exact: true,
        name: 'Manage companies',
        component: ManageCompanies
    },
    {
        path: PAGE_ROUTES.NEW_COMPANIES,
        name: 'New companies',
        component: NewCompanies
    },
    {
        path: PAGE_ROUTES.PARTNER_COMPANIES,
        name: 'Parner companies',
        component: PartnerCompanies
    },
    {
        path: PAGE_ROUTES.COMPANY,
        name: 'Edit company',
        component: Company
    },
    {
        path: PAGE_ROUTES.REPORTED_COMPANIES,
        exact: true,
        name: 'Reported companies',
        component: ReportedCompanies
    },
    {
        path: PAGE_ROUTES.REPORTED_COMPANY,
        name: 'Reported company',
        component: ReportedCompany
    },
    {
        path: PAGE_ROUTES.REPORTED_CONVERSATIONS,
        exact: true,
        name: 'Reported conversations',
        component: ReportedConversations
    },
    {
        path: PAGE_ROUTES.REPORTED_CONVERSATION,
        name: 'Reported conversation',
        component: ReportedConversation
    },
    {
        path: PAGE_ROUTES.CHANGE_PASSWORD,
        name: 'Change password',
        component: ChangePassword
    },
    {
        path: PAGE_ROUTES.INDUSTRIES,
        name: 'Categories',
        component: Industries
    },
    {
        path: PAGE_ROUTES.SECTORS,
        name: 'Subcategories',
        component: Sectors
    },
    {
        path: PAGE_ROUTES.PRODUCTS,
        exact: true,
        name: 'Subcategories',
        component: Products
    },
    {
        path: PAGE_ROUTES.POPULAR_PRODUCTS,
        name: 'Popular Products',
        component: PopularProducts
    },
    {
        path: PAGE_ROUTES.EMAILS_GENERATOR,
        name: 'Send Emails',
        component: EmailsGenerator
    },
    {
        path: PAGE_ROUTES.ADDITIONAL_STATS,
        name: 'Additional Stats',
        component: AdditionalStats
    },
    {
        path: PAGE_ROUTES.DOWNLOADS,
        name: 'Downloads',
        component: Downloads
    },
    {
        path: PAGE_ROUTES.SURVEYS,
        name: 'Surveys',
        component: Surveys
    },
    {
        path: PAGE_ROUTES.MANAGE_AWARDS,
        name: 'Manage awards',
        component: ManageAwards
    },
];

export const routesPublic: IRoute[] = [
    {
        path: PAGE_ROUTES.HOME,
        exact: true,
        name: 'Home',
        component: MainLayout
    },
    {
        path: PAGE_ROUTES.DASHBOARD,
        name: 'Dashboard',
        component: Dashboard
    },
    {
        path: PAGE_ROUTES.COMPANY,
        name: 'Edit company',
        component: Company
    },
    {
        path: PAGE_ROUTES.REPORTED_COMPANIES,
        exact: true,
        name: 'Reported companies',
        component: ReportedCompanies
    },
    {
        path: PAGE_ROUTES.REPORTED_COMPANY,
        name: 'Reported company',
        component: ReportedCompany
    },
    {
        path: PAGE_ROUTES.REPORTED_CONVERSATIONS,
        exact: true,
        name: 'Reported conversations',
        component: ReportedConversations
    },
    {
        path: PAGE_ROUTES.REPORTED_CONVERSATION,
        name: 'Reported conversation',
        component: ReportedConversation
    },
    {
        path: PAGE_ROUTES.CHANGE_PASSWORD,
        name: 'Change password',
        component: ChangePassword
    },
    {
        path: PAGE_ROUTES.INDUSTRIES,
        name: 'Categories',
        component: Industries
    },
    {
        path: PAGE_ROUTES.SECTORS,
        name: 'Products/Services',
        component: Sectors
    },
    {
        path: PAGE_ROUTES.EMAILS_GENERATOR,
        name: 'Send Emails',
        component: EmailsGenerator
    },
    {
        path: PAGE_ROUTES.ADDITIONAL_STATS,
        name: 'Additional Stats',
        component: AdditionalStats
    },
    {
        path: PAGE_ROUTES.MANAGE_AWARDS,
        name: 'Manage awards',
        component: ManageAwards
    },
    {
        path: PAGE_ROUTES.DOWNLOADS,
        name: 'Downloads',
        component: Downloads
    }
];

