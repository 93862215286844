import { FETCH_ALL } from '../actions';
import {ListReducerBuilder, GetListReducerBuilder} from '../../../../utils/reducers';
import {FETCH_LAST_TWO_WEEKS, FETCH_LAST_WEEK, FETCH_COMPANIES_WITH_SPECIFIC_FIELD, FETCH_COMPANIES_WITHOUT_SPECIFIC_FIELD, IDENTIFIER} from '../actions';

export default {
    companies: ListReducerBuilder.build(IDENTIFIER, false),
    allcompanies: GetListReducerBuilder.build(IDENTIFIER, FETCH_ALL),
    companiesLastWeek: GetListReducerBuilder.build(IDENTIFIER, FETCH_LAST_WEEK),
    companiesLastTwoWeeks: GetListReducerBuilder.build(IDENTIFIER, FETCH_LAST_TWO_WEEKS),
    companiesWithSpecificField: GetListReducerBuilder.build(IDENTIFIER, FETCH_COMPANIES_WITH_SPECIFIC_FIELD),
    companiesWithoutSpecificField: GetListReducerBuilder.build(IDENTIFIER, FETCH_COMPANIES_WITHOUT_SPECIFIC_FIELD)
};
