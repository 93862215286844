import { all } from 'redux-saga/effects';
import { WatchersBuilder } from '../../../../utils/sagas/WatchersBuilder';
import { FETCH_PLANS, START_FREE_TRIAL, IDENTIFIER } from '../../actions/Subscription';
import { environment } from '../../../../environments/environment';

export default function* subscriptionSagas() {
    yield all([
        ...WatchersBuilder.buildListGet(
            IDENTIFIER,
            FETCH_PLANS,
            environment.api.plans
        ),
        ...WatchersBuilder.buildPost(
            IDENTIFIER,
            START_FREE_TRIAL,
            environment.api.freeTrial
        ),
    ]);
}
