import * as React from 'react';
import { Modal } from 'reactstrap';

const CustomModal = ({ isModalOpen, modalOptions }) =>
    <div className="animated fadeIn">
        <Modal isOpen={isModalOpen}>
            {modalOptions && modalOptions.content}
        </Modal>
    </div>;

export default CustomModal;
