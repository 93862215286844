import {
    put
} from 'redux-saga/effects';
import {
    push,
    goBack,
    replace
} from 'react-router-redux';

export function makePush() {
    return function* (action) {
        yield put(push(action.payload));
    };
}

export function makeBack() {
    return function* () {
        yield put(goBack());
    };
}

export function makeReplace() {
    return function* (action) {
        yield put(replace(action.payload));
    };
}
