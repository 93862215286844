import {createActionName, FAILURE, PENDING, SUCCESS} from '../../core/store/actions/commonActionTypes';
import {IBaseState} from './IBaseState';

export type IActionHandler<T extends IBaseState> = (state: T, action?) => T;

export interface IActionHandlers<T extends IBaseState> {
    [action: string]: IActionHandler<T>;
}

export class ReducerSet<T extends IBaseState> {

    private readonly action: string;

    constructor(action: string) {
        this.action = action;
    }

    public static create<T extends IBaseState>(action: string, success: (state: T, payload: any) => any) {
        class Ret extends ReducerSet<T> {
            protected success(state, payload): T {
                return success(state, payload);
            }
        }

        return new Ret(action);
    }

    protected pending(state): T {
        return {
            ...state,
            pending: true,
            error: null
        };
    }

    protected success(state, payload): T {
        throw new Error('Not implemented!');
    }

    protected failure(state, payload): T {
        return {
            ...state,
            pending: false,
            error: payload
        };
    }

    public build(identifier): IActionHandlers<T> {
        return {
            [createActionName(identifier, this.action, PENDING)]: this.pending,
            [createActionName(identifier, this.action, SUCCESS)]: this.success,
            [createActionName(identifier, this.action, FAILURE)]: this.failure
        };
    }

}
