import { ReducerSet, SingleReducerBuilder } from '../../../../utils/reducers';
import { APPROVE, IDENTIFIER, REJECT, FETCH_FACEBOOK_DATA, FETCH_LINKEDIN_DATA, DELETE_PRODUCT, ATTACH_AWARD, REMOVE_AWARD } from '../actions';

export default {
    company: new SingleReducerBuilder(IDENTIFIER, false, true, false)
        .addReducerSet(ReducerSet.create(
            APPROVE,
            (state, payload) => ({
                ...state,
                pending: false,
                entity: payload,
                error: null
            })
        ))
        .addReducerSet(ReducerSet.create(
            REJECT,
            (state, payload) => ({
                ...state,
                pending: false,
                entity: payload,
                error: null
            })
        ))
        .addReducerSet(ReducerSet.create(
            ATTACH_AWARD,
            (state, payload) => ({
                ...state,
                pending: false,
                entity: payload,
                error: null
            })
        ))
        .addReducerSet(ReducerSet.create(
            REMOVE_AWARD,
            (state, payload) => ({
                ...state,
                pending: false,
                entity: payload,
                error: null
            })
        ))
        .addReducerSet(ReducerSet.create(
            FETCH_FACEBOOK_DATA,
            (state, payload) => ({
                ...state,
                pending: false,
                entity: payload,
                error: null
            })
        ))
        .addReducerSet(ReducerSet.create(
            FETCH_LINKEDIN_DATA,
            (state, payload) => ({
                ...state,
                pending: false,
                entity: payload,
                error: null
            })
        ))
        .addReducerSet(ReducerSet.create(
            DELETE_PRODUCT,
            (state, payload) => ({
                ...state,
                pending: false,
                entity: payload,
                error: null
            })
        ))
        .build()
};
