import ActionsBuilder from '../../../../utils/actions/ActionsBuilder';
import { createActionName } from '../../../../core/store/actions/commonActionTypes';
import { IAttachAward } from 'src/core/interfaces/model';

export const IDENTIFIER = '[COMPANIES]';
export const APPROVE = 'APPROVE';
export const REJECT = 'REJECT';
export const FETCH_FACEBOOK_DATA = 'FETCH_FACEBOOK_DATA';
export const FETCH_LINKEDIN_DATA = 'FETCH_FACEBOOK_DATA';
export const ATTACH_AWARD = 'ATTACH_AWARD';
export const REMOVE_AWARD = 'REMOVE_AWARD';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';

export default {
    ...ActionsBuilder.buildSingle(IDENTIFIER, false, true),
    approve: (id: number) => ({
        type: createActionName(IDENTIFIER, APPROVE),
        payload: id
    }),
    reject: (id: number) => ({
        type: createActionName(IDENTIFIER, REJECT),
        payload: id
    }),
    attachAward: (payload: IAttachAward) => ({
        type: createActionName(IDENTIFIER, ATTACH_AWARD),
        payload
    }),
    removeAward: (payload: IAttachAward) => ({
        type: createActionName(IDENTIFIER, REMOVE_AWARD),
        payload
    }),
    fetchFacebookData: (id: number) => ({
        type: createActionName(IDENTIFIER, FETCH_FACEBOOK_DATA),
        payload: id
    }),
    fetchLinkedinData: (id: number) => ({
        type: createActionName(IDENTIFIER, FETCH_FACEBOOK_DATA),
        payload: id
    }),
    deleteProduct: (payload: { id: number, productId: number }) => ({
        type: createActionName(IDENTIFIER, DELETE_PRODUCT),
        payload
    })
};
