import ActionsBuilder from '../../../../utils/actions/ActionsBuilder';
import { createActionName } from '../../../../core/store/actions/commonActionTypes';

export const IDENTIFIER = '[REPORTED_CONVERSATIONS]';
export const DO_ACTION = 'DO_ACTION';

export default {
    ...ActionsBuilder.buildList(IDENTIFIER, false, false, true),
    ...ActionsBuilder.buildSingle(IDENTIFIER),
    doAction: data => ({
        type: createActionName(IDENTIFIER, DO_ACTION),
        payload: data
    })
};
