import {LOGIN, LOGOUT} from '../../actions/Auth/authActionTypes';
import {FAILURE, PENDING, SUCCESS} from '../../actions/commonActionTypes';
import {StorageService} from '../../../services/storage';
import {IBaseState} from '../../../../utils/reducers/IBaseState';

export interface IAuthState extends IBaseState {
    userData: any;
    isLoggedIn: boolean;
}

const initialState: IAuthState = {
    userData: StorageService.userData || null,
    isLoggedIn: Boolean(StorageService.token),
    pending: false,
    error: null
};

const startPending = state => ({
    ...state,
    pending: true,
    error: null
});

const successLogin = (state, {profile}) => ({
    ...state,
    userData: profile,
    isLoggedIn: true,
    pending: false,
    error: null
});

const failureLogin = (state, payload) => ({
    ...state,
    isLoggedIn: false,
    pending: false,
    error: payload
});

const logout = state => ({
    ...state,
    isLoggedIn: false,
    userData: null
});

const actionHandlers = {
    [LOGIN + PENDING]: startPending,
    [LOGIN + SUCCESS]: successLogin,
    [LOGIN + FAILURE]: failureLogin,

    [LOGOUT + SUCCESS]: logout
};

export default (state: IAuthState = initialState, action) => {
    const handler = actionHandlers[action.type];

    return handler ? handler(state, action.payload) : state;
};
