import {Component} from 'react';
import {connect} from 'react-redux';
import {redirectTo} from '../../../core/store/actions/HistoryRouting';
import {PAGE_ROUTES} from '../../../constants/pageRoutes';
import {getUserData} from '../../../core/store/actions/Auth';
import {IActionCreator} from '../../../core/interfaces/common';

interface IProps {
    isLoggedIn: boolean;
    userId?: number;
    getUserData: IActionCreator<number>;
}

class CheckAuth extends Component<IProps> {

    public componentDidMount() {
        if (!this.props.isLoggedIn) {
            redirectTo(PAGE_ROUTES.LOGIN);
        } else {
            this.props.getUserData(this.props.userId);
        }
    }

    public render() {
        return this.props.isLoggedIn ? this.props.children : null;
    }

}

const mapDispatchToProps = {
    getUserData
};

const mapStateToProps = ({auth}) => ({
    isLoggedIn: auth.isLoggedIn,
    userId: auth.userData && auth.userData.id
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckAuth);
