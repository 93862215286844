import {
    takeLatest
} from 'redux-saga/effects';
import {
    makePush,
    makeBack,
    makeReplace
} from './historyWorkers';
import {
    REDIRECT_TO_URL,
    REDIRECT_BACK,
    REDIRECT_REPLACE
} from '../../actions/HistoryRouting/historyRoutingActionTypes';

export function* redirectTo() {
    yield takeLatest(REDIRECT_TO_URL, makePush());
}

export function* redirectBack() {
    yield takeLatest(REDIRECT_BACK, makeBack());
}

export function* redirectReplace() {
    yield takeLatest(REDIRECT_REPLACE, makeReplace());
}
