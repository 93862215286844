import {all} from 'redux-saga/effects';
import {WatchersBuilder} from '../../../../utils/sagas/WatchersBuilder';
import {IDENTIFIER} from '../actions';
import {environment} from '../../../../environments/environment';

export default function* () {
    yield all([
        ...WatchersBuilder.buildList(
            IDENTIFIER,
            environment.api.companies
        )
    ]);
}
