import {CREATE, createActionName, DELETE, FETCH_LIST, FETCH_SINGLE, UPDATE} from '../../core/store/actions/commonActionTypes';
import {IActionCreator} from '../../core/interfaces/common';

interface IActions {
}

interface IListAllActions extends IActions {
    fetchAll: IActionCreator<any>;
}

interface IListActions extends IActions {
    fetchList: IActionCreator<any>;
    createEntity?: IActionCreator<any>;
    updateEntity?: IActionCreator<any>;
    deleteEntity?: IActionCreator<number>;
}

interface ISingleActions extends IActions {
    fetchEntity: (id) => object;
    createEntity?: IActionCreator<any>;
    updateEntity?: IActionCreator<any>;
    deleteEntity?: IActionCreator<number>;
}

export default class ActionsBuilder {
    public static buildList(identifier: string, supportsCreate = true, supportsUpdate = true, supportsDelete = true): IListActions {
        const ACTION_FETCH = createActionName(identifier, FETCH_LIST);
        const ret: IListActions = {
            fetchList: params => ({
                type: ACTION_FETCH,
                payload: params
            })
        };
        if (supportsCreate) {
            const ACTION_CREATE = createActionName(identifier, CREATE);
            ret.createEntity = data => ({
                type: ACTION_CREATE,
                payload: data
            });
        }
        if (supportsUpdate) {
            const ACTION_UPDATE = createActionName(identifier, UPDATE);
            ret.updateEntity = data => ({
                type: ACTION_UPDATE,
                payload: data
            });
        }
        if (supportsDelete) {
            const ACTION_DELETE = createActionName(identifier, DELETE);
            ret.deleteEntity = id => ({
                type: ACTION_DELETE,
                payload: id
            });
        }
        return ret;
    }

    public static buildListGet(identifier: string, action: string): IListAllActions {
        const ACTION_FETCH_ALL = createActionName(identifier, action);
        const ret: IListAllActions = {
            fetchAll: params => ({
                type: ACTION_FETCH_ALL,
                payload: params
            })
        };
        return ret;
    }

    public static buildSingle(identifier: string, supportsCreate = false, supportsUpdate = false, supportsDelete = false): ISingleActions {
        const ACTION_FETCH = createActionName(identifier, FETCH_SINGLE);

        const ret: ISingleActions = {
            fetchEntity: id => ({
                type: ACTION_FETCH,
                payload: id
            })
        };

        if (supportsCreate) {
            const ACTION_CREATE = createActionName(identifier, CREATE);
            ret.createEntity = data => ({
                type: ACTION_CREATE,
                payload: data
            });
        }
        if (supportsUpdate) {
            const ACTION_UPDATE = createActionName(identifier, UPDATE);
            ret.updateEntity = data => ({
                type: ACTION_UPDATE,
                payload: data
            });
        }
        if (supportsDelete) {
            const ACTION_DELETE = createActionName(identifier, DELETE);
            ret.deleteEntity = id => ({
                type: ACTION_DELETE,
                payload: id
            });
        }

        return ret;
    }
}
