import {connect} from 'react-redux';
import {Header} from './Header';
import {logout} from '../../store/actions/Auth';

const mapStateToProps = ({auth}) => ({
    userData: auth.userData
});

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
