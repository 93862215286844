import store from '../../configureStore';
import {REDIRECT_BACK, REDIRECT_REPLACE, REDIRECT_TO_URL} from './historyRoutingActionTypes';

const dispatch = action => store.dispatch(action);

export const redirectTo = (payload: string) => dispatch({
    type: REDIRECT_TO_URL,
    payload
});

export const redirectBack = () => dispatch({
    type: REDIRECT_BACK
});

export const redirectReplace = (payload: string) => dispatch({
    type: REDIRECT_REPLACE,
    payload
});
