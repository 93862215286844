import { OPEN_MODAL, CLOSE_MODAL } from '../../actions/Modal/modalActionTypes';

const initialState = {
    isModalOpen: false,
    options: null
};

const toggleOpen = (state, payload) => ({
    ...state,
    isModalOpen: true,
    options: payload
});

const toggleClose = state => ({
    ...state,
    isModalOpen: false,
    options: null
});

const actionHandlers = {
    [OPEN_MODAL]: toggleOpen,
    [CLOSE_MODAL]: toggleClose
};

export default (state = initialState, action) => {
    const handler = actionHandlers[action.type];

    return handler ? handler(state, action.payload) : state;
};
