import {CREATE, DELETE, FETCH_LIST, UPDATE} from '../../core/store/actions/commonActionTypes';
import {ReducerBuilder} from './ReducerBuilder';
import {ReducerSet} from './ReducerSet';
import {IBaseState} from './IBaseState';
import {IBaseEntity} from '../../core/interfaces/model';
import { FETCH_ALL } from 'src/pages/Downloads/store/actions';

export interface IListRecord extends IBaseEntity {
    id: number;

    [property: string]: any;
}

export interface IListState<T = IListRecord> extends IBaseState {
    data: { data: T[] };
}

export class ListFetchedReducerSet extends ReducerSet<IListState> {
    constructor() {
        super(FETCH_LIST);
    }

    protected success(state: IListState, payload): IListState {
        return {
            ...state,
            pending: false,
            error: null,
            data: payload
        };
    }
}

export class ListFetchedAllReducerSet extends ReducerSet<IListState> {
    constructor(action) {
        super(action);
    }
    protected success(state: IListState, payload): IListState {
        return {
            ...state,
            pending: false,
            error: null,
            data: Boolean(state.data) ? {
                ...state.data,
                ...payload,
                data: [...state.data.data, ...payload.data]
            } : payload
        };
    }
}

export class EntityCreatedReducerSet extends ReducerSet <IListState> {
    constructor() {
        super(CREATE);
    }

    protected success(state: IListState, payload): IListState {
        return {
            ...state,
            pending: false,
            data: {data: [payload, ...state.data.data]},
            error: null
        };
    }
}

export class EntityUpdatedReducerSet extends ReducerSet <IListState> {
    constructor() {
        super(UPDATE);
    }

    protected success(state: IListState, payload): IListState {
        return {
            ...state,
            pending: false,
            data: {
                ...state.data,
                data: state.data.data.map(a => a.id === payload.id ? payload : a)
            },
            error: null
        };
    }
}

export class EntityDeletedReducerSet extends ReducerSet<IListState> {
    constructor() {
        super(DELETE);
    }

    protected success(state: IListState, payload): IListState {
        return {
            ...state,
            pending: false,
            data: {data: state.data.data.filter(a => a.id !== payload.id)},
            error: null
        };
    }
}

export class ListReducerBuilder extends ReducerBuilder<IListState> {

    constructor(identifier: string, supportsCreate = true, supportsUpdate = true, supportsDelete = true) {
        super(identifier, [new ListFetchedReducerSet()], {data: null});
        if (supportsCreate) {
            this.addReducerSet(new EntityCreatedReducerSet());
        }
        if (supportsUpdate) {
            this.addReducerSet(new EntityUpdatedReducerSet());
        }
        if (supportsDelete) {
            this.addReducerSet(new EntityDeletedReducerSet());
        }
    }

    public static build(identifier: string, supportsCreate = true, supportsUpdate = true, supportsDelete = true) {
        return new ListReducerBuilder(identifier, supportsCreate, supportsUpdate, supportsDelete).build();
    }

}

export class GetListReducerBuilder extends ReducerBuilder<IListState> {

    constructor(identifier: string, action: string) {
        super(identifier, [new ListFetchedAllReducerSet(action)], {data: null});
    }

    public static build(identifier: string, action: string) {
        return new GetListReducerBuilder(identifier, action).build();
    }

}
