import { DO_ACTION, IDENTIFIER } from '../actions';
import { ListReducerBuilder, ReducerSet, SingleReducerBuilder } from '../../../../utils/reducers';

const listReducerBuilder = new ListReducerBuilder(IDENTIFIER, false, false, false)
    .addReducerSet(ReducerSet.create(
        DO_ACTION,
        (state, payload) => ({
            ...state,
            pending: false,
            data: { data: state.data.data.map(a => a.id === payload.id ? payload : a) },
            error: null
        })
    ));

export default {
    reportedCompany: SingleReducerBuilder.build(IDENTIFIER),
    reportedCompanies: listReducerBuilder.build()
};
