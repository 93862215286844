import { all } from 'redux-saga/effects';
import { WatchersBuilder } from '../../../../utils/sagas/WatchersBuilder';
import { environment } from '../../../../environments/environment';
import { IDENTIFIER } from '../actions';
import { successfulCategoryUpdate, failedCategoryUpdate } from './workers';

export default function* () {
    yield all(WatchersBuilder.buildList(
            IDENTIFIER,
            environment.api.industries,
            {
                create: { successCallback: successfulCategoryUpdate },
                update: { successCallback: successfulCategoryUpdate, failureCallback: failedCategoryUpdate }
            }
        )
    );
}
