import * as React from 'react';
import { Button, Col, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import FormError from '../../../../shared/components/FormError';

const InnerLoginForm = props => {
    const { values, errors, touched, handleChange, handleBlur, handleSubmit } = props;
    return (
        <Form onSubmit={handleSubmit}>
            <h1>Login</h1>
            <p className="text-muted">Sign In to your account</p>
            <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                        <i className="icon-user"/>
                    </InputGroupText>
                </InputGroupAddon>
                <Input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    placeholder="Email"
                />
                <FormError touched={touched.email} errorText={errors.email}/>
            </InputGroup>
            <InputGroup className="mb-4">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                        <i className="icon-lock"/>
                    </InputGroupText>
                </InputGroupAddon>
                <Input
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    placeholder="Password"
                />
                <FormError touched={touched.password} errorText={errors.password}/>
            </InputGroup>
            <Row>
                <Col xs="6">
                    <Button type="submit" color="primary" className="px-4">
                        Login
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default InnerLoginForm;
