import { IStartFreeTrialPayload } from 'src/core/interfaces/model';
import ActionsBuilder from '../../../../utils/actions/ActionsBuilder';
import { createActionName } from '../commonActionTypes';

export const IDENTIFIER = '[SUBSCRIPTION_TYPE]';
export const FETCH_PLANS = 'FETCH_SUBSCRIPTION_TYPES';
export const START_FREE_TRIAL = 'START_FREE_TRIAL';

export default {
    fetchPlans: ActionsBuilder.buildListGet(IDENTIFIER, FETCH_PLANS).fetchAll,
    startFreeTrial: (payload: IStartFreeTrialPayload) => ({
        type: createActionName(IDENTIFIER, START_FREE_TRIAL),
        payload
    }),
};
