import { put } from 'redux-saga/effects';
import {
    redirectTo
} from '../../actions/HistoryRouting';
import { StorageService } from '../../../services/storage';
import { SUCCESS } from '../../actions/commonActionTypes';
import { LOGOUT } from '../../actions/Auth/authActionTypes';
import { PAGE_ROUTES } from '../../../../constants/pageRoutes';

export function redirectAfterSuccessLogin(data) {
    StorageService.token = data ? data.token : '';
    StorageService.userData = data ? data.profile : '';
    redirectTo(PAGE_ROUTES.HOME);
}

export function redirectAfterFailureLogin() {
    redirectTo(PAGE_ROUTES.LOGIN);
}

export function redirectAfterFailureUserData() {
    redirectTo(PAGE_ROUTES.LOGIN);
}

export function makeLogout() {
    return function* () {
        yield StorageService.clearStorage();
        yield put(redirectTo(PAGE_ROUTES.LOGIN));
        yield put({
            type: LOGOUT + SUCCESS
        });
    };
}
