import { disableSector } from './filter-data';

const transformToSelectOptions = (items) => items.reduce((acc, i) => [...acc, i && { value: i.id, label: i.name }], []);
export default transformToSelectOptions;

export const transform = (items, data) =>
  items.reduce((acc, i) => [
    ...acc,
    { value: i.id, label: i.name, isDisabled: disableSector(data, i.id) }
  ], []);

export const transformIndustryOptions = (items) => {
  let newArray = transformToSelectOptions(items);
  newArray = [{ value: '', label: 'All' }, ...newArray];
  return newArray;
};
