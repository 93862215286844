import {createActionName} from '../../../../core/store/actions/commonActionTypes';

export const IDENTIFIER = '[SEND_EMAILS]';

export const EXECUTE = 'EXECUTE';

export default {
    sendEmails: payload => ({
        type: createActionName(IDENTIFIER, EXECUTE),
        payload
    })
};
