export const PENDING = '_PENDING';
export const SUCCESS = '_SUCCESS';
export const FAILURE = '_FAILURE';

export const RESET = '_RESET';

export const FETCH_SINGLE = 'FETCH_SINGLE';
export const FETCH_LIST = 'FETCH_LIST';
export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';

export const createActionName = (identifier: string, action: string, state?: string): string => {
    return `${identifier} ${action}${state || ''}`;
};
