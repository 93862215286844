import { LOGIN, LOGOUT, GET_USER_DATA } from './authActionTypes';

export const login = payload => ({
    type: LOGIN,
    payload
});

export const getUserData = payload => ({
    type: GET_USER_DATA,
    payload
});

export const logout = () => ({
    type: LOGOUT
});
