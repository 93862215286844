import {FAILURE, PENDING, SUCCESS} from './commonActionTypes';

export const startProcessActionCreator = (type: string) => ({
    type: `${type}${PENDING}`
});

export const successProcessActionCreator = (type: string, payload) => ({
    type: `${type}${SUCCESS}`,
    payload
});

export const failureProcessActionCreator = (type: string, payload) => ({
    type: `${type}${FAILURE}`,
    payload
});
