import {StorageService} from '../storage';
import axios, {AxiosRequestConfig} from 'axios';
import store from '../../store/configureStore';
import {logout} from '../../store/actions/Auth';
import {toast} from 'react-toastify';
import { toggleModal } from '../../store/actions/Modal';

interface IGetRequest {
    url: string;
    params?;
}

class ApiService {

    private makeFetchRequest({url, method, data, params}: AxiosRequestConfig): Promise<any> {

        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'Authorization': StorageService.token
        };

        return new Promise((resolve, reject) => {
            axios({url, method, data, params})
                .then(res => {
                    resolve(res.data || {});
                })
                .catch(error => this.checkErrorStatus(error, resolve, reject));
        });
    }

    public get(data: IGetRequest | string): Promise<any> {
        const req = {
            url: typeof data === 'string' ? data : data.url,
            params: typeof data === 'string' ? undefined : data.params,
        };
        return this.makeFetchRequest({
            ...req,
            method: 'GET'
        });
    }

    public post(data): Promise<any> {
        return this.makeFetchRequest({
            ...data,
            method: 'POST'
        });
    }

    public put(data): Promise<any> {
        return this.makeFetchRequest({
            ...data,
            method: 'PUT'
        });
    }

    public patch(data): Promise<any> {
        return this.makeFetchRequest({
            ...data,
            method: 'PATCH'
        });
    }

    public delete(data): Promise<any> {
        return this.makeFetchRequest({
            ...data,
            method: 'DELETE'
        });
    }

    private checkErrorStatus(error, resolve, reject): void {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    if (error.response.data && error.response.data.error === 'Unauthorized') {
                        store.dispatch(logout());
                        reject(error.response);
                    }
                    break;
                case 403:
                    store.dispatch(logout());
                    reject(error.response);
                    break;
                case 404:
                    reject(error.response);
                    break;
                case 400:
                    if (error.response.data && error.response.data.message) {
                        toast.error(error.response.data.message);
                        if (store.getState().modal.isModalOpen === true) {
                            store.dispatch(toggleModal());
                        }
                    }
                    reject(error.response);
                    break;
                case 502:
                    reject(error.response);
                    break;
                case 503:
                    reject(error.response);
                    break;
                default:
                    reject(error.response);
            }
        } else {
            reject(error);
        }

    }

}

const apiService = new ApiService();
export default apiService;
