import {
    takeLatest
} from 'redux-saga/effects';
import { LOGIN, LOGOUT, GET_USER_DATA } from '../../actions/Auth/authActionTypes';
import makeSagaRequest from '../../../../utils/sagas/makeSagaRequest';
import {
    redirectAfterSuccessLogin,
    redirectAfterFailureLogin,
    makeLogout,
    redirectAfterFailureUserData
} from './authWorkers';
import { loginRequest, userDataRequest } from '../../../services/API/AuthApi';

export function* login() {
    yield takeLatest(LOGIN, makeSagaRequest({
        apiRequest: loginRequest,
        successCallback: redirectAfterSuccessLogin,
        failureCallback: redirectAfterFailureLogin
    }));
}

export function* userData() {
    yield takeLatest(GET_USER_DATA, makeSagaRequest({
        apiRequest: userDataRequest,
        failureCallback: redirectAfterFailureUserData
    }));
}

export function* logout() {
    yield takeLatest(LOGOUT, makeLogout());
}
