import {
    all
} from 'redux-saga/effects';
import { toggleModal } from './modalWatchers';

export default function* () {
    yield all([
        toggleModal()
    ]);
}
