import {call, put} from 'redux-saga/effects';
import {
    failureProcessActionCreator,
    startProcessActionCreator,
    successProcessActionCreator
} from '../../core/store/actions/commonActionCreators';
import {ISagaRequestArgs} from '../../core/interfaces/common';

export default function makeSagaRequest(config: ISagaRequestArgs) {
    const {apiRequest, successCallback, failureCallback} = config;
    return function* ({type, payload}) {
        yield put(startProcessActionCreator(type));

        try {
            const response = yield call(apiRequest, payload);
            yield put(successProcessActionCreator(type, response));
            if (successCallback) {
                yield successCallback(response);
            }
        } catch (error) {
            yield put(failureProcessActionCreator(type, error));
            if (failureCallback) {
                yield failureCallback(error);
            }
        }
    };
}
