import {all} from 'redux-saga/effects';
import {WatchersBuilder} from '../../../../utils/sagas/WatchersBuilder';
import {EXECUTE, IDENTIFIER} from '../actions';
import {changePassword} from '../../api';

export default function* () {
    yield all([
        WatchersBuilder.build(
            IDENTIFIER,
            EXECUTE,
            changePassword
        )
    ]);
}
