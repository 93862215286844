import {EXECUTE, IDENTIFIER} from '../actions';
import {ReducerBuilder, ReducerSet} from '../../../../utils/reducers';

const reducerBuilder = new ReducerBuilder(
    IDENTIFIER,
    [
        ReducerSet.create(
            EXECUTE,
            (state, payload) => ({
                ...state,
                pending: false,
                success: payload.success,
                error: payload.success ? null : true
            })
        )
    ],
    {success: null}
);

export default {
    sendEmails: reducerBuilder.build()
};
