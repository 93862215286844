import {LOCAL_STORAGE_FIELDS as fields} from './localStorageFields';

export class StorageService {

    public static get token() {
        return StorageService.get(fields.token);
    }

    public static set token(token) {
        StorageService.set(fields.token, `Bearer ${token}`);
    }

    public static get userData() {
        return StorageService.get(fields.user);
    }

    public static set userData(userData) {
        StorageService.set(fields.user, userData);
    }

    public static clearStorage() {
        Object.values(fields).forEach(name => StorageService.remove(name));
    }

    public static set(name, value, storage = localStorage) {
        storage.setItem(name, JSON.stringify(value));
    }

    public static get(name, storage = localStorage) {
        const rawValue = storage.getItem(name);

        if (rawValue === undefined || rawValue === null) {
            return null;
        }

        try {
            return JSON.parse(rawValue);
        } catch (e) {
            return rawValue || null;
        }
    }

    public static remove(name, storage = localStorage) {
        storage.removeItem(name);
    }

}
