import * as React from 'react';
import {DropdownItem, DropdownMenu, DropdownToggle, Nav} from 'reactstrap';
import {AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler} from '@coreui/react';
import {APP_NAVBAR_BRAND} from '../../../constants/configs/headerUiCofings';

export const Header = ({userData, logout}) =>
    <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile/>
        <AppNavbarBrand
            full={APP_NAVBAR_BRAND.full}
            minimized={APP_NAVBAR_BRAND.minimized}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg"/>
        <Nav className="ml-auto" navbar>
            <AppHeaderDropdown direction="down">
                <DropdownToggle nav>
                    <i className="fa fa-user-circle mr-1"/>
                    <span>
                        {
                            userData && userData.firstName && userData.lastName
                                ? `${userData.firstName} ${userData.lastName}` : ''
                        }
                    </span>
                    <i className="fa fa-caret-down ml-1 mr-4"/>
                </DropdownToggle>
                <DropdownMenu right style={{
                    right: 'auto'
                }}>
                    {/*
                    <Link to={'#'}>
                        <DropdownItem>
                            <i className="fa fa-user" />
                            Profile
                        </DropdownItem>
                    </Link>
                    */}
                    <DropdownItem onClick={() => logout()}>
                        <i className="fa fa-lock"/>
                        Logout
                    </DropdownItem>
                </DropdownMenu>
            </AppHeaderDropdown>
        </Nav>
    </React.Fragment>;
