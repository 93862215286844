import {IActionHandlers, ReducerSet} from './ReducerSet';
import {AnyAction} from 'redux';
import {IBaseState} from './IBaseState';

const DEFAULT_INITIAL_STATE: IBaseState = {
    pending: false,
    error: null
};

export class ReducerBuilder<T extends IBaseState = IBaseState> {
    private identifier: string;
    private reducerSets: Array<ReducerSet<T>>;
    private initialState: any = DEFAULT_INITIAL_STATE;

    constructor(identifier, reducerSets: Array<ReducerSet<T>> = [], initialStateExtension?: any) {
        this.identifier = identifier;
        this.reducerSets = reducerSets;
        if (initialStateExtension) {
            this.extendInitialState(initialStateExtension);
        }
    }

    public addReducerSet(reducerSet: ReducerSet<T>): this {
        this.reducerSets.push(reducerSet);
        return this;
    }

    public extendInitialState(extension): this {
        this.initialState = {
            ...this.initialState,
            ...extension
        };
        return this;
    }

    public createActionHandlers(): IActionHandlers<T> {
        return this.reducerSets.map(set => set.build(this.identifier))
            .reduce((acc, val) => ({...acc, ...val}));
    }

    public build(): (state: T, action) => T {
        const actionHandlers = this.createActionHandlers();
        return (state = this.initialState, action: AnyAction) => {
            const handler = actionHandlers[action.type];
            return handler ? handler(state, action.payload) : state;
        };
    }
}
