export const HOST = `${process.env.API_PROTOCOL}://${process.env.API_HOST}/api`;
export const APP_HOST = `${process.env.API_PROTOCOL}://${process.env.APP_HOST}`;
export const STATIC_BUCKET = `${process.env.API_PROTOCOL}://${process.env.STATIC_BUCKET}`;

export const environment = {
    api: {
        auth: {
            login: `${HOST}/admin/authenticate`
        },
        changePassword: `${HOST}/admin/administrators/change-password`,
        admins: `${HOST}/admin/administrators`,
        industries: `${HOST}/admin/industries`,
        sectors: `${HOST}/admin/sectors`,
        products: `${HOST}/admin/products`,
        companies: `${HOST}/admin/companies`,
        downloads: `${HOST}/admin/companies/download/all`,
        companyAwards: `${HOST}/admin/companies/awards`,
        companiesSpecificProperty: `${HOST}/admin/companies/download`,
        partnerCompanies: `${HOST}/admin/companies/partners`,
        plans: `${HOST}/client/subscription/setup?type=subscription`,
        freeTrial: `${HOST}/admin/subscription/free-trial`,
        feedback: {
            reportedCompanies: `${HOST}/admin/feedback/reported-companies`,
            reportedConversations: `${HOST}/admin/feedback/reported-conversations`
        },
        statistics: {
            companies: {
                byDate: `${HOST}/admin/statistics/companies/by-date`,
                volumeByDate: `${HOST}/admin/statistics/companies/volume-by-date`,
                geoDistribution: `${HOST}/admin/statistics/companies/geo-distribution`,
                dataValidity: `${HOST}/admin/statistics/companies/data-validity`,
                importedCompanies: `${HOST}/admin/statistics/companies/total`,
                emailVerified: `${HOST}/admin/statistics/companies/email-verified`,

            },
            connections: {
                requestsByDate: `${HOST}/admin/statistics/connections/requests-by-date`,
                connectionsVolumeByDate: `${HOST}/admin/statistics/connections/connections-volume-by-date`,
            },
            usersActivity: {
                profileVisits: `${HOST}/admin/statistics/users-activity/profile-visits`,
                companyProfileVisits: `${HOST}/admin/statistics/users-activity/company-profile-visits`,
                searches: `${HOST}/admin/statistics/users-activity/searches`,
                postedDeals: `${HOST}/admin/statistics/users-activity/posted-deals`,
                bidsMade: `${HOST}/admin/statistics/users-activity/bids-made`,
                messagesSent: `${HOST}/admin/statistics/users-activity/messages-sent`,
                postsCommented: `${HOST}/admin/statistics/users-activity/posts-commented`,
                postsPublished: `${HOST}/admin/statistics/users-activity/posts-published`,
                searchesWithKeyword: `${HOST}/admin/statistics/users-activity/searches-with-keyword`,
                searchesWithoutKeyword: `${HOST}/admin/statistics/users-activity/searches-without-keyword`,
                totalDealViews: `${HOST}/admin/statistics/users-activity/total-deals-views`,
                lastActiveUsers: `${HOST}/admin/statistics/users-activity/last-active-users`
            }
        },
        countries: `${HOST}/locations/countries`,
        sendEmails: `${HOST}/admin/automatic-emails/send`,
        surveys: {
            results: `${HOST}/admin/surveys/results`
        },
        awards: `${HOST}/admin/awards`
    }
};
