import {createActionName} from '../../../../core/store/actions/commonActionTypes';

export const IDENTIFIER = '[CHANGE_PASSWORD]';

export const EXECUTE = 'EXECUTE';

export default {
    changePassword: payload => ({
        type: createActionName(IDENTIFIER, EXECUTE),
        payload
    })
};
