import * as React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Container} from 'reactstrap';
import {
    AppBreadcrumb,
    AppHeader,
    AppSidebar,
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader,
    AppSidebarMinimizer,
    AppSidebarNav
} from '@coreui/react';
import { navigation, navigationPublic } from './navConfig';
import { routesAdmin, routesPublic } from '../../routes';
import Header from '../../core/components/Header';
import CustomModal from '../../core/components/Modal';
import Toastr from '../../core/components/Toastr';
import { StorageService } from '../../core/services/storage';

export const MainLayout = mainLayoutProps => {
    const emailName = StorageService.userData.email.split('@')[0];
    const isAdmin = (emailName.includes('admin') || emailName.includes('dragica') || emailName.includes('irina'));
    const routes = isAdmin ? routesAdmin : routesPublic;

    return (
    <div className="app">
        <AppHeader fixed>
            <Header/>
        </AppHeader>
        <div className="app-body">
            <AppSidebar fixed display="lg">
                <AppSidebarHeader/>
                <AppSidebarForm/>
                <AppSidebarNav navConfig={isAdmin ? navigation : navigationPublic} {...mainLayoutProps} />
                <AppSidebarFooter/>
                <AppSidebarMinimizer/>
            </AppSidebar>
            <main className="main">
                <AppBreadcrumb appRoutes={routes}/>
                <Container fluid>
                    <Switch>
                        {routes.map((route, idx) => {
                            return route.component
                                ? <Route
                                    key={idx}
                                    path={route.path}
                                    exact={route.exact}
                                    render={props => <route.component {...props} />}
                                />
                                : null;
                        })}
                        <Redirect from="/" to="/dashboard"/>
                    </Switch>
                </Container>
            </main>
        </div>
        <Toastr/>
        <CustomModal/>
    </div>
    );
};

