import { IDENTIFIER } from '../actions';
import { ReducerBuilder } from '../../../../utils/reducers';
import {
    EntityCreatedReducerSet,
    EntityDeletedReducerSet,
    EntityUpdatedReducerSet,
    ListFetchedReducerSet
} from '../../../../utils/reducers';
import transformToSelectOptions from '../../../../utils/transformToSelectOptions';

class IndustryListFetchedReducerSet extends ListFetchedReducerSet {
    public success(state, payload) {
        return {
            ...super.success(state, payload),
            selectOptions: transformToSelectOptions(payload.data)
        };
    }
}

const reducerBuilder = new ReducerBuilder(
    IDENTIFIER,
    [new IndustryListFetchedReducerSet(), new EntityCreatedReducerSet(), new EntityUpdatedReducerSet(), new EntityDeletedReducerSet()],
    { data: null }
);

export default reducerBuilder.build();
