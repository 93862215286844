export const PAGES = {
    HOME: '',
    LOGIN: 'login',
    DASHBOARD: 'dashboard',
    STATS: 'stats',
    ADMINS: 'admins',
    MANAGE_COMPANIES: 'manage-companies',
    NEW_COMPANIES: 'new-companies',
    PARTNER_COMPANIES: 'partner-companies',
    REPORTED_POSTS: 'reported-posts',
    REPORTED_COMPANIES: 'reported-companies',
    REPORTED_CONVERSATIONS: 'reported-conversations',
    CHANGE_PASSWORD: 'change-password',
    INDUSTRIES: 'industries',
    SECTORS: 'sectors',
    EMAILS_GENERATOR: 'emails-generator',
    ADDITIONAL_STATS: 'additional-stats',
    DOWNLOADS: 'downloads',
    PRODUCTS: 'products',
    SURVEYS: 'surveys',
    MANAGE_AWARDS: 'manage-awards'
};

export const PAGE_ROUTES = {
    HOME: `/${PAGES.HOME}`,
    LOGIN: `/${PAGES.LOGIN}`,
    DASHBOARD: `/${PAGES.DASHBOARD}`,
    ADMINS: `/${PAGES.ADMINS}`,
    MANAGE_COMPANIES: `/${PAGES.MANAGE_COMPANIES}`,
    NEW_COMPANIES: `/${PAGES.NEW_COMPANIES}`,
    PARTNER_COMPANIES: `/${PAGES.PARTNER_COMPANIES}`,
    COMPANY: `/${PAGES.MANAGE_COMPANIES}/:id`,
    REPORTED_POSTS: `/${PAGES.REPORTED_POSTS}`,
    REPORTED_POST: `/${PAGES.REPORTED_POSTS}/:id`,
    REPORTED_COMPANIES: `/${PAGES.REPORTED_COMPANIES}`,
    REPORTED_COMPANY: `/${PAGES.REPORTED_COMPANIES}/:id`,
    REPORTED_CONVERSATIONS: `/${PAGES.REPORTED_CONVERSATIONS}`,
    REPORTED_CONVERSATION: `/${PAGES.REPORTED_CONVERSATIONS}/:id`,
    CHANGE_PASSWORD: `/${PAGES.CHANGE_PASSWORD}`,
    INDUSTRIES: `/${PAGES.INDUSTRIES}`,
    SECTORS: `/${PAGES.SECTORS}`,
    EMAILS_GENERATOR: `/${PAGES.EMAILS_GENERATOR}`,
    ADDITIONAL_STATS: `/${PAGES.ADDITIONAL_STATS}`,
    DOWNLOADS: `/${PAGES.DOWNLOADS}`,
    PRODUCTS: `/${PAGES.PRODUCTS}`,
    POPULAR_PRODUCTS: `/${PAGES.PRODUCTS}/:id`,
    SURVEYS: `/${PAGES.SURVEYS}`,
    MANAGE_AWARDS: `/${PAGES.MANAGE_AWARDS}`
};
