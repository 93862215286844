import * as React from 'react';
import {FormFeedback} from 'reactstrap';

interface IProps {
    touched?: any;
    errorText: string;
}

const FormError = ({touched, errorText}: IProps) => {
    if (!touched || !errorText) {
        return <React.Fragment/>;
    }
    return <FormFeedback style={{display: 'block'}}>
        <span>{errorText}</span>
    </FormFeedback>;
};

export default FormError;
