import ActionsBuilder from '../../../../utils/actions/ActionsBuilder';

export const IDENTIFIER = '[COMPANIES]';
export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_LAST_WEEK = 'FETCH_LAST_WEEK';
export const FETCH_LAST_TWO_WEEKS = 'FETCH_LAST_TWO_WEEKS';
export const FETCH_COMPANIES_WITH_SPECIFIC_FIELD = 'FETCH_COMPANIES_WITH_SPECIFIC_FIELD';
export const FETCH_COMPANIES_WITHOUT_SPECIFIC_FIELD = 'FETCH_COMPANIES_WITHOUT_SPECIFIC_FIELD';

export default {
    fetchAll: ActionsBuilder.buildListGet(IDENTIFIER, FETCH_ALL).fetchAll,
    fetchLastWeek: ActionsBuilder.buildListGet(IDENTIFIER, FETCH_LAST_WEEK).fetchAll,
    fetchLastTwoWeeks: ActionsBuilder.buildListGet(IDENTIFIER, FETCH_LAST_TWO_WEEKS).fetchAll,
    fetchCompaniesWithSpecificField: ActionsBuilder.buildListGet(IDENTIFIER, FETCH_COMPANIES_WITH_SPECIFIC_FIELD).fetchAll,
    fetchCompaniesWithoutSpecificField: ActionsBuilder.buildListGet(IDENTIFIER, FETCH_COMPANIES_WITHOUT_SPECIFIC_FIELD).fetchAll
};
