import {
    all
} from 'redux-saga/effects';
import { login, logout, userData } from './authWatchers';

export default function* () {
    yield all([
        login(),
        userData(),
        logout()
    ]);
}
