import { all } from 'redux-saga/effects';
import { WatchersBuilder } from 'src/utils/sagas/WatchersBuilder';
import { IDENTIFIER } from '../actions';
import { environment } from 'src/environments/environment';
import { successfulAwardsUpdate, failedAwardsUpdate } from './workers';

export default function* () {
    yield all(WatchersBuilder.buildList(
        IDENTIFIER,
        environment.api.awards,
        {
            create: {
                successCallback: successfulAwardsUpdate,
                failureCallback: failedAwardsUpdate
            },
            update: { successCallback: successfulAwardsUpdate, failureCallback: failedAwardsUpdate }
        }
    )
    );
}
