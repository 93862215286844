import { toast } from 'react-toastify';

export function* sendEmailsWorker(payload) {
  if (payload.success) {
    toast.success('success');
  }
  else {
    toast.success('error');
  }
}
