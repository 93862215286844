import logo from '../../assets/img/brand/logo.svg';
import sygnet from '../../assets/img/brand/sygnet.svg';

export const APP_NAVBAR_BRAND = {
    full: {
        src: logo,
        width: 89,
        height: 25,
        alt: 'Enterprise League Admin'
    },
    minimized: {
        src: sygnet,
        width: 30,
        height: 30,
        alt: 'Enterprise League Admin'
    }
};
