import {FETCH_SINGLE} from '../../core/store/actions/commonActionTypes';
import {ReducerBuilder} from './ReducerBuilder';
import {ReducerSet} from './ReducerSet';
import {IBaseState} from './IBaseState';
import {EntityCreatedReducerSet, EntityDeletedReducerSet, EntityUpdatedReducerSet} from './ListReducerBuilder';

export interface ISingleState<T = any> extends IBaseState {
    entity?: T;
}

export class SingleFetchedReducerSet extends ReducerSet<ISingleState> {
    constructor() {
        super(FETCH_SINGLE);
    }

    public pending(state): ISingleState {
        return {
            ...state,
            entity: null,
            pending: true,
            error: null
        };
    }

    public success(state, payload): ISingleState {
        return {
            ...state,
            pending: false,
            entity: payload,
            error: null
        };
    }
}

export class SingleReducerBuilder extends ReducerBuilder {

    constructor(identifier: string, supportsCreate = true, supportsUpdate = true, supportsDelete = true) {
        super(identifier, [new SingleFetchedReducerSet()], {entity: null});
        if (supportsCreate) {
            this.addReducerSet(new EntityCreatedReducerSet());
        }
        if (supportsUpdate) {
            this.addReducerSet(new EntityUpdatedReducerSet());
        }
        if (supportsDelete) {
            this.addReducerSet(new EntityDeletedReducerSet());
        }
    }

    public static build(identifier: string, supportsCreate = true, supportsUpdate = true, supportsDelete = true) {
        return new SingleReducerBuilder(identifier, supportsCreate, supportsUpdate, supportsDelete).build();
    }

}
