import {
    all
} from 'redux-saga/effects';
import {
    redirectTo,
    redirectBack,
    redirectReplace
} from './historyWatchers';

export default function* () {
    yield all([
        redirectTo(),
        redirectBack(),
        redirectReplace()
    ]);
}
