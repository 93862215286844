import {all} from 'redux-saga/effects';
import {WatchersBuilder} from '../../../../utils/sagas/WatchersBuilder';
import {FETCH_LAST_TWO_WEEKS, FETCH_LAST_WEEK, FETCH_COMPANIES_WITH_SPECIFIC_FIELD, FETCH_COMPANIES_WITHOUT_SPECIFIC_FIELD, IDENTIFIER} from '../actions';
import {environment} from '../../../../environments/environment';
import { FETCH_ALL } from '../actions';

export default function* () {
    yield all([
        ...WatchersBuilder.buildListGet(
            IDENTIFIER,
            FETCH_ALL,
            environment.api.downloads
        ),
        ...WatchersBuilder.buildListGet(
            IDENTIFIER,
            FETCH_LAST_WEEK,
            environment.api.downloads
        ),
        ...WatchersBuilder.buildListGet(
            IDENTIFIER,
            FETCH_LAST_TWO_WEEKS,
            environment.api.downloads
        ),
        ...WatchersBuilder.buildListGet(
            IDENTIFIER,
            FETCH_COMPANIES_WITH_SPECIFIC_FIELD,
            environment.api.companiesSpecificProperty
        ),
        ...WatchersBuilder.buildListGet(
            IDENTIFIER,
            FETCH_COMPANIES_WITHOUT_SPECIFIC_FIELD,
            environment.api.companiesSpecificProperty
        )
    ]);
}
