import * as React from 'react';
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import {ConnectedRouter} from 'react-router-redux';
import store, {history} from './core/store/configureStore';
import {Login, MainLayout} from './pages';
import CheckAuth from './shared/components/CheckAuth';
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css';

export const App = () =>
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Switch>
                <Route exact path="/login" name="Login Page" component={Login}/>
                <Route path="/" render={props =>
                    <CheckAuth>
                        <MainLayout {...props} />
                    </CheckAuth>
                }/>
            </Switch>
        </ConnectedRouter>
    </Provider>;

