import { IAttachAward } from 'src/core/interfaces/model/ICompany';
import apiService from '../../../core/services/API';
import { environment } from '../../../environments/environment';

export function approve(id: number) {
    return apiService.post({
        url: `${environment.api.companies}/${id}/approve`
    });
}

export function reject(id: number) {
    return apiService.post({
        url: `${environment.api.companies}/${id}/reject`
    });
}

export function attachAward(data: IAttachAward) {
    return apiService.put({
        url: `${environment.api.companyAwards}`,
        data
    });
}

export function removeAward(data: IAttachAward) {
    return apiService.delete({
        url: `${environment.api.companyAwards}`,
        data
    });
}

export function fetchFacebookData(id: number) {
    return apiService.get({
        url: `${environment.api.companies}/${id}/facebook`
    });
}

export function fetchLinkedinkData(id: number) {
    return apiService.get({
        url: `${environment.api.companies}/${id}/linkedin`
    });
}

export function deleteProduct(data: { id: number, productId: number }) {
    return apiService.delete({
        url: `${environment.api.companies}/${data.id}/products/${data.productId}`
    });
}
