import { put, select } from 'redux-saga/effects';
import { OPEN_MODAL, CLOSE_MODAL } from '../../actions/Modal/modalActionTypes';

export function makeToggle() {
    return function* ({ payload }: any) {
        const isModalOpen = yield select(state => state.modal.isModalOpen);
        if (!isModalOpen) {
            yield put({
                type: OPEN_MODAL,
                payload
            });
        } else {
            yield put({
                type: CLOSE_MODAL
            });
        }
    };
}
