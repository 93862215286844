import {all} from 'redux-saga/effects';
import {WatchersBuilder} from '../../../../utils/sagas/WatchersBuilder';
import {EXECUTE, IDENTIFIER} from '../actions';
import {sendEmails} from '../../api';
import {sendEmailsWorker} from './workers';

export default function* () {
    yield all([
        WatchersBuilder.build(
            IDENTIFIER,
            EXECUTE,
            sendEmails,
            sendEmailsWorker
        )
    ]);
}
