export const navigation = {
    items: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'icon-speedometer'
        },
        {
            name: 'Additional Stats',
            url: '/additional-stats',
            icon: 'icon-speedometer'
        },
        {
            name: 'Administrators',
            url: '/admins',
            icon: 'icon-shield'
        },
        {
            name: 'Categories',
            url: '/industries',
            icon: 'icon-link'
        },
        {
            name: 'Products/Services',
            url: '/sectors',
            icon: 'icon-link'
        },
        {
            name: 'Popular Products',
            url: '/products',
            icon: 'icon-link'
        },
        {
            name: 'Manage companies',
            url: '/manage-companies',
            icon: 'icon-organization'
        },
        {
            name: 'Manage awards',
            url: '/manage-awards',
            icon: 'icon-organization'
        },
        {
            name: 'New companies',
            url: '/new-companies',
            icon: 'icon-organization'
        },
        {
            name: 'Partner companies',
            url: '/partner-companies',
            icon: 'icon-organization'
        },
        {
            name: 'Reported companies',
            url: '/reported-companies',
            icon: 'icon-drop'
        },
        {
            name: 'Reported conversations',
            url: '/reported-conversations',
            icon: 'icon-drop'
        },
        {
            name: 'Send emails',
            url: '/emails-generator',
            icon: 'icon-pencil'
        },
        {
            name: 'Change password',
            url: '/change-password',
            icon: 'icon-settings'
        },
        {
            name: 'Downloads',
            url: '/downloads',
            icon: 'icon-speedometer'
        },
        {
            name: 'Surveys',
            url: '/surveys',
            icon: 'icon-speedometer'
        }
    ]
};

export const navigationPublic = {
    items: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'icon-speedometer'
        },
        {
            name: 'Additional Stats',
            url: '/additional-stats',
            icon: 'icon-speedometer'
        },
        {
            name: 'Categories',
            url: '/industries',
            icon: 'icon-link'
        },
        {
            name: 'Products/Services',
            url: '/sectors',
            icon: 'icon-link'
        },
        {
            name: 'Reported companies',
            url: '/reported-companies',
            icon: 'icon-drop'
        },
        {
            name: 'Reported conversations',
            url: '/reported-conversations',
            icon: 'icon-drop'
        },
        {
            name: 'Send emails',
            url: '/emails-generator',
            icon: 'icon-pencil'
        },
        {
            name: 'Change password',
            url: '/change-password',
            icon: 'icon-settings'
        },
        {
            name: 'Downloads',
            url: '/downloads',
            icon: 'icon-speedometer'
        }
    ]
};

