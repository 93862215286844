interface IFieldsValues {
    [fieldName: string]: string;
}

export declare type ValidationRule = (value: string, otherValues: IFieldsValues) => string | boolean | null | undefined;

interface IFieldsRules {
    [fieldName: string]: ValidationRule[];
}

export class ValidationBuilder {
    private fields: IFieldsRules[] = [];

    private LinksRegexMap: { [websiteName: string]: RegExp } = {
        LinkedIn: /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile|company)?\//,
        Facebook: /(?:(?:http|https):\/\/)?(?:www.)?(?:facebook.com)\/(\w+)/,
        Instagram: /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/,
        Twitter: /(?:(?:http|https):\/\/)?(?:www.)?(?:twitter.com)\/(\w+)/,
        Crunchbase: /(?:(?:http|https):\/\/)?(?:www\.)?crunchbase\.com\/(\w+)/,
        Clutch: /(?:(?:http|https):\/\/)?(?:www\.)?clutch\.co\/(\w+)/,
        GoodFirms: /(?:(?:http|https):\/\/)?(?:www\.)?goodfirms\.com\/(\w+)/,
        CompanyHouse: /(?:(?:http|https):\/\/)?(?:www\.)?companyhouse\.com\/(\w+)/,
        // TO DO - need to generate new regex for GoodFirms and CompanyHouse
    };
    public addRule(fieldName: string, rule: ValidationRule): ValidationBuilder {
        if (!this.fields[fieldName]) {
            this.fields[fieldName] = [];
        }
        this.fields[fieldName].push(rule);
        return this;
    }

    public regex(fieldName: string, regex: RegExp, errorMessage: string): ValidationBuilder {
        return this.addRule(fieldName, (value) => !!value && !!value.trim() && !regex.test(value) ? errorMessage : false);
    }

    public required(fieldName: string, displayName: string): ValidationBuilder {
        return this.addRule(fieldName, (value) => !value || !value.trim() ? `${displayName} is required` : false);
    }

    public maxLength(fieldName: string, maxLength: number): ValidationBuilder {
        return this.addRule(fieldName, (value) => {
            if (value && value.length > maxLength) {
                return `${fieldName} must be maximum ${maxLength} characters long`;
            }
            return false;
        });
    }

    public email(fieldName: string): ValidationBuilder {
        return this.regex(fieldName, /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i, 'Email address is not valid');
    }

    public link(fieldName: string): ValidationBuilder {
        return this.regex(fieldName, /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi, 'Invalid url');
    }

    public google(fieldName: string): ValidationBuilder {
        return this.regex(fieldName, /(?:(?:http|https):\/\/)?(?:www.)?(?:google.com)\/(\w+)/, 'Invalid google link');
    }

    public phone(fieldName: string): ValidationBuilder {
        return this.regex(fieldName, /^[\d\s()-]*$/g, 'Phone number is not valid');
    }

    public validateLink(fieldName: string, websiteName: string): ValidationBuilder {
        const regexPattern = this.LinksRegexMap[websiteName];
        return this.regex(fieldName, regexPattern, `${websiteName} URL is not valid`);
    }

    public customerId(fieldName: string): ValidationBuilder {
        return this.regex(fieldName, /^cus_[a-zA-Z0-9]+$/, 'Customer ID is not valid.');
    }

    public number(fieldName: string, min?: number, max?: number): ValidationBuilder {
        return this.addRule(
            fieldName,
            (value) => {
                if (!value || !value.trim()) {
                    return false;
                }
                if (!/-?\d+/.test(value)) {
                    return 'Invalid number format';
                }
                const i = parseInt(value, 10);
                if (min !== undefined && i < min) {
                    return 'Value must be greater or equal to ' + min;
                }
                if (max !== undefined && i > max) {
                    return 'Value must be less or equal to ' + max;
                }
                return false;
            }
        );
    }

    public password(fieldName: string): ValidationBuilder {
        return this.addRule(
            fieldName,
            (value) => {
                if (value.length < 8) {
                    return 'Password must be minimum 8 characters length';
                }
                let hasCapital = false;
                let hasLower = false;
                let hasDigit = false;
                for (const c of value) {
                    if (c >= 'a' && c <= 'z') {
                        hasLower = true;
                    } else if (c >= 'A' && c <= 'Z') {
                        hasCapital = true;
                    } else if (c >= '0' && c <= '9') {
                        hasDigit = true;
                    }
                }
                if (!hasCapital) {
                    return 'Password must contain at least one capital letter';
                }
                if (!hasLower) {
                    return 'Password must contain at least on lower letter';
                }
                if (!hasDigit) {
                    return 'Password must contain at least one digit';
                }
                return false;
            }
        );
    }

    public passwordConfirmation(fieldName: string, passwordFieldName: string): ValidationBuilder {
        return this.addRule(fieldName, (value, otherValues) => value !== otherValues[passwordFieldName] ? 'Passwords do not match' : false);
    }

    public build(): (values: IFieldsValues, props) => object | undefined {
        return (values) => Object.keys(values)
            .reduce(
                (acc, fieldName: string) => {
                    if (!this.fields[fieldName]) {
                        return acc;
                    }
                    const value = values[fieldName];
                    for (const rule of this.fields[fieldName]) {
                        try {
                            const result = rule(value !== undefined && value !== null ? String(value) : '', values);
                            if (result) {
                                return { ...acc, [fieldName]: result };
                            }
                        } catch (e) {
                            console.error(e);
                            throw e;
                        }
                    }
                    return acc;
                },
                {}
            );
    }
}
