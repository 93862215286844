import { all } from 'redux-saga/effects';
import { WatchersBuilder } from '../../../../utils/sagas/WatchersBuilder';
import { environment } from '../../../../environments/environment';
import { DO_ACTION, IDENTIFIER } from '../actions';
import { doAction } from '../../api';
import { navigateToList } from './workers';

export default function* () {
    yield all([
        ...WatchersBuilder.buildList(
            IDENTIFIER,
            environment.api.feedback.reportedCompanies,
            {
                create: { apiCall: false },
                update: { apiCall: false },
                delete: { apiCall: false }
            }
        ),
        ...WatchersBuilder.buildGetSingle(
            IDENTIFIER,
            environment.api.feedback.reportedCompanies
        ),
        WatchersBuilder.build(
            IDENTIFIER,
            DO_ACTION,
            doAction,
            navigateToList
        )
    ]);
}
