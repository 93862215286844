import { redirectReplace } from '../../../../core/store/actions/HistoryRouting';
import { PAGE_ROUTES } from '../../../../constants/pageRoutes';
import { takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

export function navigateToList() {
    redirectReplace(PAGE_ROUTES.NEW_COMPANIES);
}

export function* failureEditCompany() {
    yield takeLatest('[COMPANIES] UPDATE_FAILURE', () => {
        toast.error('Update failed!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        });
    });
}

export function* successEditCompany() {
    // CURRENTLY NOT WORKING DUE TO A BUG with the actionName UPDATE_SUCCESS
    yield takeLatest('[COMPANIES] UPDATE_SUCCESS', () => {
        toast.success('Update successful!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        });
    });
}

export function* fetchFacebookDataMessage(payload) {
    if (payload.success) {
        toast.success('Fetching Facebook data success!');
    }
    else {
        toast.error('Fetching Facebook data has failed!');
    }
}

export function* fetchLinkedinDataMessage(payload) {
    if (payload.success) {
        toast.success('Fetching Linkedin data success!');
    }
    else {
        toast.error('Fetching LinkedIn data has failed!');
    }
}

export function* attachAwardCallback(payload) {
    if (payload) {
        toast.success('Award successfully attached!');
        window.location.reload();
    }
    else {
        toast.error('Award upload failed!');
    }
}

export function* removeAwardCallback(payload) {
    if (payload) {
        toast.success('Award successfully removed!');
        window.location.reload();
    }
    else {
        toast.error('Award removing failed!');
    }
}

export function* deleteProductCallback(payload) {
    if (payload.success) {
        toast.success('Product successfully deleted!');
        location.reload();
    }
    else {
        toast.error('Deleting product failed!');
    }
}
