import {all} from 'redux-saga/effects';
import {WatchersBuilder} from '../../../../utils/sagas/WatchersBuilder';
import {environment} from '../../../../environments/environment';
import {IDENTIFIER} from '../actions';
import { closeModal } from './workers';

export default function* () {
    yield all(WatchersBuilder.buildList(
        IDENTIFIER,
        environment.api.products,
        {
            create: {successCallback: closeModal},
            update: {successCallback: closeModal}
        }
    ));
}
