import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import historyRoutingSagas from './HistoryRouting';
import modalSagas from './Modal';
import adminsSagas from '../../../pages/Admins/store/sagas';
import industriesSagas from '../../../pages/Industries/store/sagas';
import sectorsSagas from '../../../pages/Sectors/store/sagas';
import companySagas from '../../../pages/CompanyDetails/store/sagas';
import companiesSagas from '../../../pages/ManageCompanies/store/sagas';
import downloadsSagas from '../../../pages/Downloads/store/sagas/index';
import reportedCompaniesSagas from '../../../pages/ReportedCompanies/store/sagas';
import reportedConversationsSagas from '../../../pages/ReportedConversations/store/sagas';
import changePasswordSagas from '../../../pages/ChangePassword/store/sagas';
import partnerCompaniesSagas from '../../../pages/PartnerCompanies/store/sagas';
import emailsGeneratorSagas from '../../../pages/EmailsGenerator/store/sagas';
import products from '../../../pages/Products/store/sagas';
import awards from 'src/pages/ManageAwards/store/sagas';
import subscriptionSagas from './Subscription';

export default function* rootSaga() {
    yield all([
        authSagas(),
        historyRoutingSagas(),
        modalSagas(),
        adminsSagas(),
        industriesSagas(),
        sectorsSagas(),
        companySagas(),
        companiesSagas(),
        downloadsSagas(),
        reportedCompaniesSagas(),
        reportedConversationsSagas(),
        changePasswordSagas(),
        partnerCompaniesSagas(),
        emailsGeneratorSagas(),
        products(),
        awards(),
        subscriptionSagas()
    ]);
}
