import apiService from '../';
import { environment } from '../../../../environments/environment';

export function loginRequest(data) {
    return apiService.post({
        url: environment.api.auth.login,
        data
    });
}

export function userDataRequest(id) {
    return apiService.get({
        url: `${environment.api.admins}/${id}`
    });
}
